import React from 'react'
import { css } from '@emotion/core'
import Fade from 'react-reveal/Fade'
import {
  colors,
  spacer,
  breakpoints,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import { LinkBtn } from '@dqp/common/components/Button/Button'
import ribbons from '#images/howitworks/ribbons.svg'
import routes from '#globals/routes'
import { reactRevealDuration } from '#globals/constants'

const styles = {
  container: css`
    position: relative;
    ${breakpoints({
      paddingTop: [80, 80, 120, 120, 120],
      paddingBottom: [80, 80, 120, 120, 120],
    })};
  `,
  title: css`
    ${spacer.mrB30};
  `,
  text: css`
    line-height: 150%;
    ${spacer.mrB30};
  `,
  ribbons: css`
    position: absolute;
    left: 50%;
    top: 50%;
    width: 110%;
    height: 110%;
    transform: translate(-50%, -58%);
  `,
}
function PricingCTA() {
  const titleStyleProps = {
    size: 'xxxxxLarge',
    weight: 'bold',
    color: colors.black,
    css: styles.title,
  }
  return (
    <div
      className='outer'
      style={{
        background: colors.yellow,
        zIndex: 1,
        position: 'relative',
      }}
    >
      <section
        css={styles.container}
        style={{ zIndex: 3, position: 'relative' }}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <Fade clear cascade duration={reactRevealDuration}>
              <div className='col-lg-9 text-center'>
                <Title as='h2' {...titleStyleProps}>
                  Want to go faster?
                </Title>
                <Text
                  size='large'
                  color={colors.charcoal}
                  css={styles.text}
                >
                  If your child can’t get enough and would like to
                  work through more than one question per day, you can
                  sign up to our annual plan to gain immediate
                  exclusive access to the full year’s programme (770
                  questions and expert video tutorials). Furthermore,
                  we have excellent annual discounts. Check out our
                  pricing
                </Text>
                <LinkBtn
                  variant='blue'
                  size='large'
                  inline
                  to={routes.pricing}
                >
                  OUR PRICING
                </LinkBtn>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <img
        alt='ribbons'
        css={styles.ribbons}
        src={ribbons}
        className='d-none d-md-block'
        style={{ zIndex: 2 }}
      />
    </div>
  )
}

export default PricingCTA
